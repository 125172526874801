export const BASE_URL = 'https://deliveryvhgp-webapi.azurewebsites.net/api/v1/'
export const BASE_URL_V2 =
  'https://deliveryvhgp-webapi.azurewebsites.net/api/v2/'
export const BASE_URL_CORAL_TEAM_VERSION = 'https://api.vhgp.net/api/v1/'
//export const BASE_URL_CORAL_TEAM_VERSION = 'https://localhost:7102/api/v1/'

export const STORE = 'store-management'
export const PRODUCT = 'products'
export const CATEGORY = 'category-management'
export const MENU = 'menus'
export const SHIP = 'shipper-management'
export const ORDER = 'order-management'
