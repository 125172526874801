import React from 'react'
import BasicMap from './BasicMap';

export const ShipperLocation = () => {
    return(
        <>
            <BasicMap/>
        </>
    );
} 

